export class ConfigurationKey {
    public static readonly AutomaticYearWeekDetermine = 'BatchChecker:Settings:AutomaticYearWeekDetermine'
    public static readonly SelectedYearWeek = 'BatchChecker:Settings:SelectedYearWeek'
    public static readonly AutomaticPocessingIsRunning = 'BatchChecker:Settings:AutomaticPocessingIsRunning'

}

export const FileStatusColor: Record<string, string> = {
    Old_File: 'LightCoral',
    Missing: 'Orange',
    Importing: 'NavajoWhite',
    Imported: 'PaleGreen',
    Imported_Duplicate_File_Waiting: 'Orange',
    ImportError: 'LightCoral',
    Ready: 'LightGrey'
}


export const FileImportStatus = {
    OldFile: 'Old_File',
    Missing: 'Missing',
    Importing: 'Importing',
    Imported: 'Imported',
    ImportedDuplicateWaiting: 'Imported_Duplicate_File_Waiting',
    ImportError: 'ImportError',
    Ready: 'Ready'
}


