import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { AuthService } from '../../services/auth.service';
import { UserRoles } from '../../models/enum';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { UserDetailsService } from '../../services/user-details-service';
import { DashboardService } from '../../services/dashboard.service';
import { LocalStoreManager } from '../../services/local-store-manager-service';
import { ConfigurationKey } from '../../models/constant';
import { AutomatedProcessingService } from '../../services/automated-process.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatTooltip, MatIconModule, MatMenuModule, RouterOutlet, RouterLink, RouterLinkActive, MatSidenavModule, MatListModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})


export class HeaderComponent implements OnInit {
  title = 'Batch Checker';
  name: string | null = '';
  // public roleList = Roles;
  public userTypeList = UserRoles;
  isAutomatedProcess!: boolean | null;
  constructor(public authService: AuthService, private readonly automatedProcessService: AutomatedProcessingService, private readonly localStoreManager: LocalStoreManager, private readonly dashboardService: DashboardService, private readonly userDetailsService: UserDetailsService,) {
  }

  ngOnInit(): void {
    this.name = this.userDetailsService.getItems()?.userName ?? '';
     this.isAutomatedProcess = this.localStoreManager.getData(ConfigurationKey.AutomaticPocessingIsRunning) == null ? null: this.localStoreManager.getData(ConfigurationKey.AutomaticPocessingIsRunning) == "true";
    this.automatedProcessService.refresh$.subscribe(() => {
      this.isAutomatedProcess = this.localStoreManager.getData(ConfigurationKey.AutomaticPocessingIsRunning) == "true";
    });
  }

  logout() {
    this.authService.logout();
  }

}