import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpGeneralService } from './http-general.service';
import { APIConstants } from '../models/api-constants';
import { APIResponseGet } from '../models/api-response/api-response';
import { ApplicationInsightsService } from './application-insights.service';
import { AuditLogRequest } from '../models/api-request/audit-log-request';
import { AutomatedProcessResponse } from '../models/api-response/automated-process-response';
import { AuditLogView } from '../models/view-model/audit-log';

@Injectable({
    providedIn: 'root'
})
export class AutomatedProcessingService {

    constructor(private readonly httpGeneralService: HttpGeneralService, private readonly applicationInsightsService: ApplicationInsightsService) {
    }
    private readonly refreshSubject = new Subject<void>();
    refresh$ = this.refreshSubject.asObservable();
    triggerRefresh() {
        this.refreshSubject.next();
    }

    getAutomatedProcessList(): Observable<APIResponseGet<AutomatedProcessResponse>> {
        this.applicationInsightsService.logTrace('Method Get api/batchchecker/automaticprocessupdate is called.');
        return this.httpGeneralService.get<AutomatedProcessResponse>(APIConstants.automaticprocessupdate);
    }

    getAuditLogs(requestModel: AuditLogRequest): Observable<APIResponseGet<AuditLogView[]>> {
        this.applicationInsightsService.logTrace('Method Get api/batchchecker/auditlogs is called.');
        return this.httpGeneralService.get(requestModel.fileName != null && requestModel.processDate != null ? APIConstants.auditlogs + 'date/' + requestModel.processDate + '/fileName/' + requestModel.fileName : APIConstants.auditlogs, 180000);
    }

}
